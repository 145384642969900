.mainViewContainer {
  padding-top: 60px;
  @media (max-width: 768px) {
    padding-top: 40px;
  }
}

.categoryIcon {
  img {
    width: 45px;
    object-fit: contain;
  }
}
