.adsHeaderBanner {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.adsHeaderImage {
  flex: 1;
  min-width: 270px;
  margin-bottom: 20px;
  margin-right: 20px;

  @media (max-width: 615px) {
    margin-right: 0;
  }
}

.filterViewsRow {
  @media (max-width: 576px) {
    display: none;
  }
}

.filterPanelForMobile {
  height: 748px;
  margin-top: 5px;
  transition: height 0.3s ease;
  background-color: white;
}

.filterPanel {
  background-color: white;
}

.mainViewContainer {
  padding-top: 40px;
  @media (max-width: 803px) {
    padding-top: 10px;
  }
}

.filterMenuBar {
  padding: 10px;
  background-color: white;
  // border: 1px solid #d9d9d9;
  // border-radius: 5px;
  box-shadow: 1px 4px 2px -2px #d9d9d9;
  @media (min-width: 576px) {
    display: none;
  }
}

.colHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70px;
  @media (max-width: 576px) {
    height: 60px;
  }
}

.bikesGridViewHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
