.blackColor {
  color: black;
}
.redColor {
  color: red;
}
// .bikeInformation span:nth-child(3n) {
//   float: right;
//   margin-left: 200px;
// }

.colHeader {
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 70px;
  @media (max-width: 576px) {
    height: 60px;
  }
}

.bikeInformationListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left:20px;

  span {
    text-align: right;
  }
}

.mainViewContainer {
  padding-top: 40px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
}

.detailView {
  background-color: white;
  padding: 70px 0;
  @media (max-width: 768px) {
    padding: 30px 0;
  }
}

.buttonWrapper {
  margin-top: 5px;
  margin-bottom: 5px;
  @media (max-width: 768px) {
    margin-top: 10px;
    margin-bottom: 30px;
  }
}

.helpQuestionView {
  margin: 125px 0;
  @media (max-width: 768px) {
    margin: 50px 0;
  }
  @media (max-width: 576px) {
    margin: 30px 0 10px 0;
  }
}

.collapseRow {
  display: flex;
  align-items: center;
}

.collapseNoButton {
  min-width: 22px !important;
  width: 22px;
  height: 22px;
  margin-right: 10px;
}

.carouselCol {
  padding-left: 30px;
  @media (max-width: 767px) {
    padding-left: 0;
  }
}

.carouselWrapper {
  ul {
    padding: 0;

    li {
      background-color: white !important;
    }
  }
}

.carouselImageWrapper {
}
