.imageButtonContainer {
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 15px 15px 20px;
  margin: 0 auto;
  min-width: 270px;
  max-width: 300px;

  > div h1 {
    // margin-left: 5px;

    font-weight: 500;
    line-height: 30px;
    margin-bottom: 10px;
  }

  > div p {
    margin-left: 5px;
    color: white;
    font-size: 14px;
  }
}

.smallH1Font {
  font-size: 20px;
}
.smallPFont {
  font-size: 14px;
}
