.bikeComponentList {
  min-height: 105px;
  margin: 0 13px;
}

.bikeComponentList.ant-list-split .ant-list-item {
  border-bottom: none;
  padding: 0;
}

.ant-card-meta-title {
  /* white-space: normal !important; */
}
