.listItems {
  margin-right: 20px;
}

.menuBarImage {
  height: 100%;
  width: auto;
  // width: 100%;
  // height: 272px;

  // @media (max-width: 1200px) {
  //   // width: 400px;
  //   height: 230px;
  // }

  // @media (max-width: 992px) {
  //   // width: 300px;
  //   height: 200px;
  // }
  @media (max-width: 767px) {
    display: none;
  }
}

.menuBarImgWrapper {
  text-align: right;
  @media (max-width: 768px) {
    text-align: center;
  }
}
