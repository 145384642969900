.appWrapper {
  margin: 0 8px;
}

.appPrimaryColor {
  background-color: #ee3143;
}

.maxWidth {
  max-width: 1200px;
}
