.bikeContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  background-color: white;
}

.redFontColor {
  color: red;
}
.strikeThrough {
  text-decoration: line-through;
}

.greenFontColor {
  color: green;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: red;
  border-radius: 50%;
  display: flex;
}

.moreInfoButton {
  border: none;
  background-color: transparent;
  color: red;
}

.descriptionWrapper {
  width: 100%;
  display: flex;
  padding-right: 20px;
  padding-left: 10px;

  p {
    width: 100%;
    color: black;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
