.footerTextInformation {
  color: white;
  p {
    width: 250px;
  }

  @media (max-width: 768px) {
    margin-bottom: 30px;
    text-align: center;

    h1 {
      font-size: 22px;
    }

    p {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }
  }
}

h1 {
  color: white;
}
